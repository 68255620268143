import React, { useState } from "react"
import clsx from "clsx"
import { Box, Typography, CardContent, Grid, CardActions, makeStyles } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins"
import Button from "@components/Buttons/Button.jsx"
import IconButton from "@components/Buttons/IconButton"
import MainWrap from "@components/Wrap/MainWrap"
import Card from '@components/Cards/Card.jsx'
import MobileModal from "./MobileModal/MobileModal"
import questionBack from "@static/images/MainPage/question_photo.png"
import Container from "@components/Container/Container.jsx"
import Link from "@components/Buttons/Link.jsx"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import { graphql, useStaticQuery } from "gatsby"
import { getStrapiContentByLang } from "../../heplers.js"
import Markdown from "markdown-to-jsx"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import FormComp from "./Form"
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  // Верхний блок
  info_component__container: {
    display: "flex",
    flexDirection: "column",
  },
  info_component__content: {
    height: "902px",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      zIndex: -5,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      filter: "brightness(.5)"
    }
  },
  info_component__header: {
    paddingTop: "94px"
  },
  info_component__box: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "178px",
    paddingBottom: "130px",
  },
  whiteText: {
    color: palette.white.main
  },
  imageContainer: {
    [breakpoints.down("md")]: {
      display: "none"
    }
  },
  info_component__mobile_text: {
    marginRight: "15px",
    [breakpoints.down("sm")]: {
      fontWeight: "normal",
      fontSize: "18px",
      marginRight: "0px",
    }
  },
  // Блок "Мы поможем с"
  helpHeaderPadding: {
    [breakpoints.down("md")]: {
      textAlign: "center",
      padding: "0px"
    }
  },
  background_text: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "144px",
    lineHeight: "171px",
    letterSpacing: "0.08em",
    color: palette.primary.light,
    zIndex: "-5",
    [breakpoints.down("md")]: {
      opacity: "0"
    }
  },
  textOne: {
    textAlign: "left",
    position: "relative",
    top: "-80px"
  },
  textTwo: {
    textAlign: "right",
    position: "relative",
  },
  btnIcon: {
    width: "134px",
    height: "134px"
  },
  boxIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "25px",
    transition: ".25s ease",
    "&:hover":{
      transform: "translateY(-5px)"
    }
  },
  paddingSign: {
    paddingTop: "15px"
  },
  // Блок "Важные объявления"
  newsHeader: {
    paddingTop: "87px",
    [breakpoints.down("md")]: {
      paddingTop: "50px"
    }
  },
  date: {
    color: palette.secondary.main,
    fontSize: "20px",
    paddingBottom: "10px"
  },
  title: {
    fontSize: "20px",
    fontWeight: "500",
    color: palette.primary.main,
    paddingBottom: "12px"
  },
  description: {
    paddingBottom: "5px",
    fontSize: "18px",
    overflow: "hidden",
    textOverflow: "clip",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
  },
  headerLine: {
    borderTop: "3px solid #0072BB",
    width: "233px",
    paddingBottom: "10px"
  },
  cardContainer: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "30px",
    minHeight: "310px",
    transition: ".25s ease",
    "&:hover":{
      transform: "scale(1.05)"
    }
  },
  newsBox: {
    display: "flex",
    [breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },
  // Блок с формой вопроса
  mobileButtonVisability: {
    display: "none",
    [breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "50px"
    }
  },
  textBlack: {
    display: "none",
    paddingTop: "132px",
    [breakpoints.down("md")]: {
      display: "block",
      paddingTop: "0px"
    }
  },
  desktopForm: {
    height: "700px",
    [breakpoints.down("sm")]: {
      display: "none"
    }
  },
  sizeBody: {
    height: "800px"
  },
  formContainer: {
    marginTop: "132px",
    paddingBottom: "50px",
    position: "relative",
    "&::before":{
      [breakpoints.down("md")]: {
        display: "none"
      },
      content: '""',
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: -5,
      position: "absolute",
      background: `url(${questionBack}) center no-repeat`,
      backgroundSize: "cover"
    }
  },
  // общее
  headerPadding: {
    [breakpoints.down("md")]: {
      textAlign: "center",
    }
  },
  visible_desktop_form: {
    width: "480px",
    [breakpoints.down("md")]: {
      display: "none"
    }
  }
})

const request = graphql`{
  allStrapiMainPage {
    edges {
      node {
        locale
        SecondScreen {
          Image {
            localFile {
              publicURL
            }
          }
          Base {
            Title
            Description
          }
        }
        FourScreen {
          Base {
            Title
          }
          Ads {
            id
            ShortDescription
            Title
            createdAt(formatString: "DD.MM.YYYY")
          }
        }
        FirstScreen {
          Image {
            localFile {
              publicURL
            }
          }
          Base {
            Title
          }
        }
        ThirdScreen {
          Base {
            Title
          }
          Links {
            Content
            Link
            Image {
              localFile {
                publicURL
              }
            }
          }
        }
        FiveScreen {
          Base {
            Title
            Description
          }
          Image {
            localFile {
              publicURL
            }
          }
        }
        MainPageSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`;

const makeImage = (url) => makeStyles({
  root: {
    "&::before": {
      background: `url(${url}) center no-repeat`,
      backgroundSize: "cover"
    },
  }
})().root;

const MainPage = ({state, setState}) => {
  const {
    // Блок с хедерами и картинками
    info_component__container,
    info_component__content,
    info_component__header,
    info_component__box,
    whiteText,
    info_component__mobile_text,
    imageContainer,
    // Блок "Мы поможем с"
    helpHeaderPadding,
    background_text,
    textOne,
    textTwo,
    btnIcon,
    boxIcon,
    paddingSign,
    // Блок "Важные объявления"
    newsHeader,
    date,
    title,
    description,
    headerLine,
    cardContainer,
    // Блок с формой для вопросов
    mobileButtonVisability,
    textBlack,
    desktopForm,
    sizeBody,
    // Общее
    headerPadding,
    formContainer,
    visible_desktop_form
  } = makeComponentStyles(styles)

  const [alertState, setAlertState] = useState(false);
  const [modalMobileState, setModalMobileState] = useState(false)
  const [formPropKey, setPropKey] = useState((Math.random() + 1).toString(36).substring(7));
  const data = useStaticQuery(request);
  const Intl = useIntl();

  const {
    FirstScreen,
    SecondScreen,
    ThirdScreen,
    FourScreen,
    FiveScreen,
    MainPageSEO,
  } = getStrapiContentByLang(data.allStrapiMainPage.edges, Intl.locale)

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  const getCardStyle = number => {
    const style = { background: "#B7E4FF" };
    const level = Math.round(number / 2);
    const position = number % 2 ? "first" : "second";
    return isDesktop
      ? (
        level % 2 === 0
          ? position === "first" ? style : {}
          : position === "second" ? style : {}
      )
      : position === "second" ? style : {}
  }

  return (
    <div>
      <Seo title={MainPageSEO?.TitleSEO}
           description={MainPageSEO?.DescriptionSEO}
           keywords={MainPageSEO?.KeywordsSEO.split(',')}
      />

      <>
        {/*<<<<<<<<<<<<<<<< Верхний блок  >>>>>>>>>>>>>>>>>>>>>>>>>>*/}
        <Box className={info_component__container}>
          <Box className={clsx(info_component__content, makeImage(FirstScreen.Image.localFile.publicURL))}>
            <Container mw={"lg"}>
              <Typography className={clsx(info_component__header, whiteText)} variant="h1">
                <Markdown>{FirstScreen.Base.Title}</Markdown>
              </Typography>
            </Container>
          </Box>
          <Container mw={"lg"}>
            <Box className={info_component__box}>
              <Box>
                <Typography className={info_component__mobile_text} variant="h2" style={{ paddingBottom: "27px" }}>
                  <Markdown>{SecondScreen.Base.Title}</Markdown>
                </Typography>
                <Typography className={info_component__mobile_text}>
                  <Markdown>{SecondScreen.Base.Description}</Markdown>
                </Typography>
              </Box>
              <Box className={imageContainer}>
                <img src={SecondScreen.Image.localFile.publicURL} alt="image" />
              </Box>
            </Box>
          </Container>
        </Box>
        {/*<<<<<<<<<<<<<<<< Блок "Мы поможем с" >>>>>>>>>>>>>>>>>>>>>>>>>>*/}
        <Container mw={"lg"}>
          <MainWrap>
            <MainWrap.Header>
              <Typography className={clsx(helpHeaderPadding, headerPadding)} component="h1" variant="h2">
                <Markdown>{ThirdScreen.Base.Title}</Markdown>
              </Typography>
            </MainWrap.Header>
            <MainWrap.Body>
              <div>
                <Typography className={clsx(background_text, textOne)}>
                  ПОМОЩЬ
                </Typography>
                <Grid container>
                  {
                    ThirdScreen.Links.map(({ Content, Link, Image }) => (
                      <Grid item xs={6} lg={3}>
                        <div className={boxIcon}>
                          <IconButton color="blue" className={btnIcon} onClick={() => navigate('/' + Link)}><img src={Image.localFile.publicURL} alt="clothes" /></IconButton>
                          <Typography className={paddingSign} >{Content}</Typography>
                        </div>
                      </Grid>
                    ))
                  }
                </Grid>
                <Typography className={clsx(background_text, textTwo)}>
                  ПОМОЩЬ
                </Typography>
              </div>
            </MainWrap.Body>
          </MainWrap>
        </Container>
        {/*<<<<<<<<<<<<<<<< Блок "Важные объявления" >>>>>>>>>>>>>>>>>>>>>>>>>>*/}
        <Container mw={"lg"}>
          <MainWrap>
            <MainWrap.Header>
              <Typography className={clsx(headerPadding, newsHeader)} component={"h1"} variant={"h2"}>
                {FourScreen.Base.Title}
              </Typography>
            </MainWrap.Header>
            <MainWrap.Body>
              <Grid container spacing={0}>
                {
                  FourScreen.Ads.map(({ ShortDescription, Title, createdAt, id }, number) => (
                    <Grid item md={6} xs={12}>
                      <Card radius={0}
                            className={cardContainer}
                            style={getCardStyle(number + 1)}
                      >
                        <CardContent style={{ padding: "0px" }}>
                          <div className={headerLine}></div>
                          <Typography className={date}>{createdAt}</Typography>
                          <Typography className={title}>{Title}</Typography>
                          <Typography>
                            <Markdown class={description}>{ShortDescription}</Markdown>
                          </Typography>
                        </CardContent>
                        <CardActions style={{ justifyContent: "flex-end", padding: "0px" }}>
                          <Link to={`/ads/${id}`}
                                style={{ color: "#686868", textDecoration: "underline" }}>
                            { Intl.formatMessage({ id: "detail" }) }
                          </Link>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                }
              </Grid>
            </MainWrap.Body>
          </MainWrap>
        </Container>
        {/*<<<<<<<<<<<<<<<< Блок с формой для вопросов >>>>>>>>>>>>>>>>>>>>>>>>>>*/}
        <Box className={formContainer} id="main_contactus">
          <Container mw={"lg"}>
            <MainWrap className={desktopForm}>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#fff" }}>
                  {FiveScreen.Base.Title}
                </Typography>
                <Typography className={clsx(textBlack, headerPadding)} component={"h1"} variant={"h2"}
                            style={{ color: "#000" }}>
                  {FiveScreen.Base.Title}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body className={sizeBody} style={{ height: "800px" }}>
                <Grid container>
                  <div className={visible_desktop_form}>
                    <FormComp title={FiveScreen.Base.Description} key={formPropKey} setKey={setPropKey}/>
                  </div>
                  {/*<<<<<<<<<<<<<<<< Кнопка для мобилки >>>>>>>>>>>>>>>>>>>>>>>>>>*/}
                  <Grid className={mobileButtonVisability} item xs={12}>
                    <Button onClick={() => setModalMobileState(true)} type={"primary"}>{Intl.formatMessage({id: "btn_question"})}</Button>
                    <MobileModal title={FiveScreen.Base.Description} state={modalMobileState} setState={setModalMobileState} />
                  </Grid>
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Box>
      </>
    </div>
  )
}

export default MainPage
