import React, { useState, useEffect, useContext } from "react"
import Layout from "@components/Layout/Layout";
import { makeComponentStyles } from "../components/mixins"
import MainPage from "../components/MainPage/MainPage"

const styles = () => ({
  indexLayout: {
    "& > main":{
      paddingBottom: 0
    }
  }
})

const Index = ({ data }) => {
  const { indexLayout } = makeComponentStyles(styles);

  return (
    <>
      <Layout className={indexLayout}>
        <MainPage />
      </Layout>
    </>
  )
}

export default Index;
