import React from 'react'
import { Card as MuiCard } from "@material-ui/core";

import { makeComponentStyles } from "@components/mixins.js"

import clsx from "clsx"

const styles = ({ palette }) => ({
    root: {
        background: palette.primary.light,
        borderRadius: 0
    }
});

function Card({ children, className = "", ...rest }) {
    const classes = makeComponentStyles(styles)
    const props = {
        ...rest,
        elevation: 0,
        className: clsx(classes.root, className)
    }
    return (
        <MuiCard
            {...props}
        >
            {children}
        </MuiCard>
    )
}

export default Card;