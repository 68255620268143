import React, { useEffect, useState } from "react"
import { Typography, Grid } from "@material-ui/core"
import { FormGroup, FormControl, Form, FormFeedback, Label } from "@components/Forms"
import { makeComponentStyles } from "@components/mixins"
import Button from "@components/Buttons/Button.jsx"
import required from "@rules/required.js"
import email from "@rules/email.js"
import { useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../../heplers"
import { graphql, useStaticQuery } from "gatsby"
import Modal from "@components/Dialog/Modal.jsx"
import Alert from "../Dialog/Alert"
import { CONTACT } from '@api/modules/requests'
import useMediaQuery from "@material-ui/core/useMediaQuery"
import clsx from "clsx"

const styles = ({ palette, breakpoints }) => ({
  form: {
    padding: "30px 40px 40px 40px",
    // width: "480px",
    background: palette.white.main,
  },
  formHeader: {
    textAlign: "center",
    paddingBottom: "20px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px"
  },
  send_button: {
    margin: "30px auto 15px auto",
    display: "block"
  },
  alert: {
    [breakpoints.down("md")]: {
      display: "none"
    }
  }
})

const initBody = {
  academic: "",
  name: "",
  email: "",
  phone: "",
  message: ""
}

const FormComp = ({ closeModal, modal = false, title = "", setKey = () => {} }) => {

  const {
    form,
    formHeader,
    send_button,
    alert
  } = makeComponentStyles(styles)

  const Intl = useIntl();
  const [body, setBody] = useState(initBody)
  const [alertState, setAlertState] = useState(null);

  const observeBody = (key, event) => {
    const getTargetValue = () => {
      switch (event.target.type){
        default: return event.target.value
      }
    }
    setBody(body => ({
      ...body,
      [key]: getTargetValue()
    }))
  }

  useEffect(() => {
    if(alertState !== null && alertState === false) {
      if(modal) closeModal();
      setKey((Math.random() + 1).toString(36).substring(7));
    }
  }, [alertState]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      const { name, email, phone, academic, message, ...rest } = body;
      const formBody = {
        ...rest,
        user: {
          name: name,
          email: email,
          phone: phone,
        },
        academic: academic,
        message: message,
      }
      const { data } = await CONTACT(formBody);
      setAlertState(true);
    } catch(err){
      console.log(err.response);
    }
    body.name = "";
    body.email = "";
    body.phone = "";
    body.academic = "";
    body.message = "";
  }

  return (
    <>
      <Form validate onSubmit={handleSubmit} className={clsx({ [form]: !modal })}>
        <Grid container justifyContent="center">
          <Grid xs={12}>
            <Typography className={formHeader} component={"p"} variant={"p"}>
              {title}
            </Typography>
          </Grid>
          {/*<<<<<<<<<<<<<<<< Направление >>>>>>>>>>>>>>>>>>>>>*/}
          <Grid item xs={12}>
            <FormGroup name={'academic-input'}>
              <FormControl
                type={"select"}
                onChange={observeBody.bind(null, "academic")}
                validations={[required]}
                value={body.academic}
              >
                <option value="Выберите из списка">{Intl.formatMessage({id: "choose_list"})}</option>
                <option value="Бакалавр">{Intl.formatMessage({id: "bachelor"})}</option>
                <option value="Магистр">{Intl.formatMessage({id: "master"})}</option>
                <option value="Аспирант">{Intl.formatMessage({id: "phd"})}</option>
              </FormControl>
              <FormFeedback/>
            </FormGroup>
          </Grid>
          {/*<<<<<<<<<<<<<<<< Имя >>>>>>>>>>>>>>>>>>>>>*/}
          <Grid item xs={12}>
            <FormGroup name={"name-input"}>
              <Label>{Intl.formatMessage({id: "name"})}</Label>
              <FormControl
                type="name"
                onChange={observeBody.bind(null, "name")}
                value={body.name}
                validations={[required]}
              />
              <FormFeedback />
            </FormGroup>
          </Grid>
          {/*<<<<<<<<<<<<<<<< Email >>>>>>>>>>>>>>>>>>>>>*/}
          <Grid item xs={12}>
            <FormGroup name={"email-input"}>
              <Label>{Intl.formatMessage({id: "email_h"})}</Label>
              <FormControl
                type="email"
                onChange={observeBody.bind(null, "email")}
                value={body.email}
                validations={[required, email]}
              />
              <FormFeedback />
            </FormGroup>
          </Grid>
          {/*<<<<<<<<<<<<<<<< Номер телефона >>>>>>>>>>>>>>>>>>>>>*/}
          <Grid item xs={12}>
            <FormGroup name={"phone-input"}>
              <Label>{Intl.formatMessage({id: "phone_num"})}</Label>
              <FormControl
                type="tel"
                mask={"+7(999)-999-99-99"}
                onChange={observeBody.bind(null, "phone")}
                value={body.phone}
                validations={[required]}
              />
              <FormFeedback />
            </FormGroup>
          </Grid>
          {/*<<<<<<<<<<<<<<<< Сообщение >>>>>>>>>>>>>>>>>>>>>*/}
          <Grid item xs={12}>
            <FormGroup name={"message-input"}>
              <Label>{Intl.formatMessage({id: "message"})}</Label>
              <FormControl
                type="message"
                onChange={observeBody.bind(null, "message")}
                value={body.message}
                validations={[required]}
              />
              <FormFeedback />
            </FormGroup>
          </Grid>
          <Button type="submit" className={send_button}>{Intl.formatMessage({id: "send"})}</Button>
        </Grid>
      </Form>
      <Alert
        state={alertState === null ? false : alertState}
        setState={setAlertState}
        className={alert}
      >
        {Intl.formatMessage({id: "alert_message"})}
      </Alert>
    </>
  )
}

export default FormComp
