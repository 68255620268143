import React, { useEffect, useState } from "react"
import Modal from "@components/Dialog/Modal.jsx"
import FormComp from "../Form"


const MobileModal = ({state, setState, title}) => {

  return (
    <>
      <Modal
        title={title}
        state={state}
        setState={setState}
        width="sm"
      >
        <FormComp modal={true} closeModal={() => setState(false)}/>
      </Modal>
    </>
  )
}

export default MobileModal
